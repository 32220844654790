const home = {
  title: 'COAP (Center for Optimization Algorithm Platform)',
  subtitle: `COAP is a free online service for solving numerical optimization problems. 
  Since its establishment, COAP has been supported by many domestic and international researchers 
  from Shanghai University of Finance and Economics, Stanford operations research, 
  Beijing International Center for Mathematical Research(Peking University), 
  Shanghai Center for Mathematical Sciences(Fudan University), Tsinghua Institute for Interdisciplinary Sciences, etc. 
  COAP platform is developed for the purpose of supporting academic students and researchers in aspects of scientific computing, 
  especially problems of operations research.`,
  solvers: {
    title: `Solvers`,
    info: `Support solving numerical optimization problems concurrently by both commercial and open-source solvers, including COPT, COIN, Leaves, etc.
    We keep integrating more popular solvers on markets
    `,
  },
  tools: {
    title: `Tools`,
    info: `View job status, manage jobs in queue, and retrieve optimization logs and solution of finished jobs`,
  },
  statistics: {
    title: `Statistics`,
    info: `View data of problem types, inputs, and different solvers on COAP server`,
  },
  about: {
    title: 'about',
    desc: `COAP, Center for Optimization Algorithm Platform, is a free online service for solving numerical optimization problems, 
    which is hosted by Shanghai University of Finance and Economics and collaborates with leading industry and academic partners, 
    including Cardinal Operations with Stanford operations research, Beijing International Center for Mathematical Research（Peking University）, 
    Shanghai Center for Mathematical Sciences（Fudan University）, X-Technology with Tsinghua University, 
    etc. The distributed high-performance servers and related software are provided by Shanghai University of Finance and Economics, 
    and Cardinal Operations provides technical support.`,
    support: `The distributed high-performance servers and related software are provided by Shanghai University of Finance and Economics`,
  },
  showMore: 'show more',
  quickStart: 'Get Started',
};

export default home;

const signup = {
  signUpAgreement: `I have read and accept to`,
  signUpAgreementLink: ` the End User License Agreement (EULA)`,
  signUpAgreementAnd: ` and`,
  signUpAgreementLink2: ` Privacy Policy.`,
  signUpGroup1: 'Login your account by username/email',
  signUpGroup2: 'Improve your personal information',
  usernameFormat:
    'A username only contain alphanumeric characters (letters A-Z, numbers 0-9) with the exception of underscore (_) and hyphen (-).\nA username cannot be shorter than 5 characters and its first character should start with a letter.\nUsernames are case-insensitive. That is, "Admin" is the same user as "admin".',
  registrationForm: 'Registration Form',
  betterExperience:
    'Better Online Optimization Experience——after registering for free, you can enjoy the personal problem set management function, conveniently manage the problem set and view the historical results',
  activeSuccess: `The activation is successful, please use your account or email to log in to the system.`,
  activeError: `Activation failed, please check your email.`,
};

export default signup;

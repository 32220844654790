const profile = {
  name: 'Profile',
  updateProfile: 'Update Profile',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  changeEmail: 'Change Email',
  changeEmailDesc:
    'Please enter your new email, click send code, then enter the verification code received in your email',
  activity: 'Activity',
  deleteAccount: 'Delete Account',
  accountInfo: 'Account',
  createTime: 'Registration time',
  updateTime: 'Last update time',
  home: 'Home',
  account: 'Account',
  accountTooltip: 'Username cannot be changed once registered',
  tooltips: {
    deleteAccount: {
      title: 'Are you sure you want to do this?',
      message:
        'We will immediately delete all of your problem files, along with all of your problem set, requests, and results. Your username will be available to anyone on COAP',
    },
  },
  username: 'Your username or email',
  password: 'Confirm your password',
};

export default profile;
